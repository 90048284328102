* {
    --extra-small-font: 10px;
    --small-font: 12px;
    --normal-font: 14px;
    --medium-font: 16px;
    --large-font: 20px;
    --extra-lg-font: 24px;
    --white-three: #f2f2f2;
    --white-four: #f8f8f8;
    --white-five: #fcfcfc;
    --white-six: #fafafa;
    --white-seven: #ffffff;
    --elemental-grey: #a0a0a0;
    --zinc-dust: #5b5b5b;
    --ancestral-water: #d0d0d0;
    --white-smoke: #f5f5f5;
    --silver-snippet: #8f8f8f;
    --dove-grey: #6c6c6c;
    --sooty: #141414;
    --cape-hope: #d7d7d7;
    --blue-cola: #007cdc;
    --downriver: #0a2058;
    --pixel-white: #dbdbdb;
    --bleuchatel-blue: #488ffe;
    --pinball: #d3d3d3;
    --black: #000000;
    --inoffensive-blue: #123c7d;
    --silver-two: #c2cad1;
    --dusk: var(--dusk);
    --rust-red: #bf0e08;
    --slate-grey: #61677e;
    --mercury: #ebebeb;
    --spanish-grey: #979797;
    --blue-sparkle: #0076ff;
    --dugong: #707070;
    --fresh-water: #c7e5fa;
    --sanctuary-spa: #6ab3e6;
    --berlin-blue: #538dcb;
    --royal-consort: #305486;
    --sheer-peach: #fff6e6;
    --covered-in-platinum: #b9b9b9;
    --pale-grey-eleven: #e3e6ea;
    --duck-egg-blue-two: #daeaf7;
    --grey-blue: #66788a;
    --red-door: #ac0000;
    font-family: 'Lato', sans-serif;
}

.myconnectPage {
    font-family: 'Lato', sans-serif;
    background-color: #F5F7FA;
    overflow: hidden;
    height: 100vh;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-9 {
    padding-left: 9px;
}

.bs-tooltip-top.show {
    left: 18px !important;
}

/* width */
.myconnectPage ::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.myconnectPage ::-webkit-scrollbar-track {
    background: #eae8ea;
}

/* Handle */
.myconnectPage ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

/* Handle on hover */
.myconnectPage ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

.myconnectPage .content-div {
    margin: 55px 5px 0;
    height: calc(100vh - 70px);
    padding: 24px 27px 0px 30px;
    box-shadow: 0 2px 4px 0 #0000000d;
    background-color: var(--white-seven);
    overflow-y: hidden;
    overflow-x: hidden;
}

.myconnectPage .content-div h2 {
    font-size: var(--large-font);
    font-weight: bold;
    color: var(--downriver);
    // margin-bottom: 15px;
}

.myconnectPage .content-div .breadcrumb {
    background-color: transparent;
    padding: 0px 0 20px 0;
    margin-top: 0px;
    margin-bottom: 0px;
    border-bottom: none;
}

.myconnectPage .content-div .breadcrumb .breadcrumb-item {
    font-size: var(--small-font);
    font-weight: 600;
    color: var(--zinc-dust);
}

.myconnectPage .content-div .breadcrumb .breadcrumb-item.active {
    color: var(--blue-cola);
}

.myconnectPage .content-div .breadcrumb-item+.breadcrumb-item::before {
    content: "|";
}

.content-box {
    background: #FFFFFF;
    // box-shadow: 0px 1px 3px #00000029;
    border-radius: 0px;
    padding: 20px 15px;
    border-right: solid 1px #e0e0e0;
    border-top: solid 1px #e0e0e0;
    height: 100vh;
}

.content-box .category {
    margin-bottom: 6px;
    cursor: pointer;
    padding: 10px 0;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #dadadb;
    background-color: #f7f7f7;
}

.content-box .category.active,
.content-box .category:hover {
    box-shadow: 0 3px 32px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #ffa400;
    background-color: #fff;
}

.content-box .category h6 {
    color: #0A235F;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: -5px;
    padding-left: 40px;
    padding-top: 1px;
}

.content-box .category img {
    padding-top: 7px;
    padding-left: 10px;
}

.content-box .category span {
    color: #99A0A7;
    font-size: 10px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 20px;
}

.content-box .icon-bar select {
    padding: 8px 9px 7px 6px;
    border-radius: 3px;
    border: solid 0.5px #d3d3d3;
    background-color: #fafafa;
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    min-width: 130px;
}

.content-box .icon-bar ul li svg:hover path {
    fill: #007CDC;
}

.content-box .icon-bar label {
    font-size: 13px;
    font-weight: 600;
    color: #5b5b5b;
}

.content-box .icon-bar ul li {
    display: inline-block;
}

.content-box .icon-bar a {
    margin-right: 12px;
}

.content-box .icon-bar .form-control {
    background: url(../img/searchIcon.svg) no-repeat;
    background-position: 3% 50%;
    background-color: #FAFAFA;
    border: 0.5px solid #DBDBDB;
    border-radius: 3px;
    color: #617485;
    font-size: var(--small-font);
    font-weight: 300;
    height: 36px;
    padding: 0 10px 0 30px;
    width: 300px;
}

.content-box .jqx-widget-content {
    border: unset;
}

.content-box .jqx-grid-column-header div:not(:first-child) div:first-child {
    margin-left: 0 !important;
}

.content-box .jqx-widget-content div[role="row"] div[columnindex="1"] .jqx-grid-cell-left-align{
    padding-left: 19px;
}

.content-box  .jqx-widget-header div[role="columnheader"]:not(:first-child) div:not(.jqx-checkbox) div {
    margin-left: 0 !important;
}

.content-box  .jqx-grid-cell-left-align{
    margin-left: 0 !important;
}

.content-box .no-scroll div[id^=horizontalScrollBar] {
    display: none;
}

.content-box .jqx-widget-header {
    border-color: #DBDBDB !important;
    background: #F5F5F5 !important
}

.content-box .jqx-widget-header.jqx-grid-pager {
    border-color: unset !important;
    background: unset !important;
    margin-top: 7px;
    border: unset;
}

.content-box .jqx-grid-column-header {
    border-style: unset;
    background: #F5F5F5;
    color: #5B5B5B;
    font-size: 13px;
    font-weight: 600;
}

.content-box .jqx-checkbox-default {
    margin: 0px 2px 0px 10px;
}

.content-box .jqx-checkbox-default div,
.content-box .jqx-checkbox-default .jqx-checkbox-check-checked {
    width: 14px !important;
    height: 14px !important;
    background-color: #fff;
    border-color: #DBDBDB;
    border-radius: 2px;
}

.content-box .jqx-checkbox-check-indeterminate {
    width: 12.5px !important;
    height: 12.5px !important;
}

.content-box .jqx-grid-cell {
    border-style: unset;
    border-bottom: 0.5px solid #DBDBDB;
    color: #5B5B5B;
    font-size: 12px;
}

.content-box .jqx-grid-cell svg:hover path {
    fill: #007CDC !important;
}

.content-box .jqx-grid-pager div div:nth-child(7),
.content-box .jqx-grid-pager div div:nth-child(6) {
    display: none;
}

.content-box .jqx-grid-pager .jqx-button.jqx-fill-state-normal {
    border: unset;
    background-color: transparent;
}

.content-box .jqx-fill-state-pressed {
    background: #FCFCFC
}

.mx-n30 {
    margin-right: -30px;
    margin-left: -30px;
}

.leftCategoryDiv {
    height: calc(100vh - 30vh);
    overflow-y: auto;
    overflow-x: hidden;
}

.leftCategoryDiv::-webkit-scrollbar {
    width: 5px;
}

.gridViewContent .col-3 {
    flex: 0 0 19.8%;
    max-width: 19.8%;
    padding-left: 8px;
}

.tileContent {
    padding: 10px 9.5px 15.9px 10px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #f7f7f7;
    width: 180px;
    margin-bottom: 15px;
    cursor: pointer;
}

.tileContent:hover,
.tileContent.active {
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F3A73B;
}

.tileContent:hover svg .fill-color,
.tileContent.active svg .fill-color {
    fill: #56A9DB !important;
}

.tileContent input[type=checkbox] {
    height: 14px;
    width: 14px;
}

.tileContent .form-control:focus {
    box-shadow: unset;
}

.tileContent h6 {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
}

.tileContent p {
    font-size: 10px;
    font-weight: 500;
    color: #5b5b5b;
    margin: 0;
}

.tileContent .col-6 img {
    margin-top: -8px;
}

.tileContent .dropdown-toggle::after {
    display: none;
}

.tileContent .dropdown .dropdown-item {
    font-size: 12px;
    color: #000;
}

.listViewContent .jqxListGridTable div[role=row] {
    cursor: pointer;
}

.caret-down {
    background-image: url(../img/drop-down.svg);
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    float: right;
}

.caret-up {
    background-image: url(../img/drop-up.svg);
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-position: center;
    float: right;
}

.leftCategoryDiv .sub-menu+ul li a {
    font-size: 12px;
    font-weight: bold;
    color: #0a235f;
    width: 100%;
    display: inline-block;
    padding-left: 40px;
}

.leftCategoryDiv .sub-menu+ul li .sub-menu+ul li a {
    padding-left: 60px;
}

.leftCategoryDiv .sub-menu+ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 5px;
}

.leftCategoryDiv .sub-menu+ul li {
    padding: 6px 0;
    // padding-left: 40px;
}

.leftCategoryDiv .sub-menu+ul li:hover,
.leftCategoryDiv .sub-menu+ul li.active {
    background-color: #f7f7f7;
}

.leftCategoryDiv .sub-menu+ul li a:hover {
    text-decoration: none;
}

.leftCategoryDiv .category-container ul.nav {
    margin-left: 15px;
    width: 86.5%;
}

.leftCategoryDiv .category-container ul.nav li.category {
    margin-bottom: 6px;
    cursor: pointer;
    padding: 6px 0;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 #0000000f;
    border: solid 1px #dadadb;
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 0;
    // height: 52px;
}

.leftCategoryDiv .category-container ul.nav li a {
    padding: 0;
    padding-right: 10px;
}

.leftCategoryDiv .category-container ul.nav li img {
    margin-top: 4px;
    padding-left: 10px;
}

.leftCategoryDiv .category-container ul.nav li h6 {
    color: #0A235F;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: -5px;
    padding-left: 10px;
    padding-top: 1px;
    display: inline;
}

.leftCategoryDiv .category-container ul.nav li span {
    color: #99A0A7;
    font-size: 11px;
    font-weight: 600;
    padding-left: 38px;
    padding-right: 20px;
    display: block;
    margin-top: 2px;
}

.leftCategoryDiv .category-container div[id^=submenu] ul {
    padding-left: 0;
    list-style: none;
}

.leftCategoryDiv .category-container div[id^=submenu] ul li span,
.leftCategoryDiv .category-container div[id^=submenu] ul li a {
    padding: 8px 10px 8px 20px;
    font-size: 12px;
    font-weight: bold;
    color: #0a235f;
}

.leftCategoryDiv .category-container div[id^=submenu1sub] ul li a {
    padding: 8px 10px 8px 60px;
}

.leftCategoryDiv .category-container div[id^=submenu] ul li a:hover,
.leftCategoryDiv .category-container div[id^=submenu] ul li.active {
    background-color: #f7f7f7;
}

.leftCategoryDiv .category-container div[id^=submenu] ul li.active a{
    color: #000;
}

.leftCategoryDiv .category-container ul.nav li.category.active,
.leftCategoryDiv .category-container ul.nav li.category:hover {
    box-shadow: 0 3px 32px 0 #0000000f;
    border: solid 1px #ffa400;
    background-color: #fff;
}

.leftCategoryDiv .category-container .nav-link[data-toggle].collapsed:after {
    content: url(../img/drop-down.svg);
    float: right;
    margin-top: -36px;
    margin-right: 2px;
}

.leftCategoryDiv .category-container .nav-link[data-toggle]:not(.collapsed):after {
    content: url(../img/drop-up.svg);
    float: right;
    margin-top: -36px;
    margin-right: 2px;
}

.message-div {
    position: absolute;
    top: 50px;
    padding: 10px 20px;
    color: #fff;
    font-size: 14px;
    font-family: 'Lato';
    font-weight: bold;
    letter-spacing: 0.1px;
    box-shadow: 12px 8px 44px 0 #1b3a921f;
    border-radius: 3px;
    left: 27%;
    min-width: 34vw;
    height: 44px;
}

.message-div span:last-child {
    padding-top: 1px;
    padding-left: 10px;
}

.message-div img {
    margin-top: -2px;
}

.message-div.success {
    background-color: #27ad8b;
}

.yearSelect,
.yearRangeSelect {
    display: none;
}

@media(min-width:1460px) {
    .tileContent {
        width: 190px;
    }
}