.sideSheet {
    background: #fff;
    width: 47%;
    position: fixed;
    top: 0;
    display: none;
    height: 100vh;
    /*padding: 0 10px;*/
    overflow: auto;
    z-index: 9999;
    border: solid 0.5px #dbdbdb;
    background-color: #fafafa;
    left: 27%;
    // transform: translate(-50%, 0);
}

.sideSheet .close-block {
	position: fixed;
    margin-left: -60px;
    margin-top: 10px;
}

.sideSheet-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none;
}

.sideSheet-backdrop.show {
	display: block;	
    opacity: 0.8 !important;
}

.sideSheetContent {
	border: 1px solid #dee2e6;
	background-color: #fff;
	border-radius: 5px;
	padding: 13px 15px;
}

.c-pointer {
	cursor: pointer;
}

.sideSheet .titleDiv {
	background-color: #fff;
    padding: 0px 15px;
    border-bottom: 1px solid #dee2e6;
}

.sideSheet img {
    width: 100%;
}

.sideSheet .top-header, .sideSheet .bottom-footer {
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    height: 63px;
}

.sideSheet .bottom-footer{
    text-align: right;
    padding: 15px 10px;
}

.sideSheet .top-header h6 {
    color: #0A2058;
    text-shadow: 0px 3px 16px #00000029;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    margin: 0 -15px;
    padding: 20px 15px;
}

.sideSheet .top-header a {
    font-size: 12px;
    color: #007CDC;
}

.sideSheet .bottom-footer a.btn {
    border-radius: 3px;
    height: 32px;
    font-size: 12px;
    padding: 6px 20px;
}

.sideSheet .bottom-footer a.btn img{
    height: 14px;
    width: 16px;
    margin-right: 10px;
    margin-top: -3px;
}

.sideSheet .bottom-footer a.btn.download-btn {
    background: #274AC7;
    box-shadow: inset 0px -1px 1px #435A6F33;
    color: #fff;
}

.sideSheet .bottom-footer a.btn.email-btn {
    box-shadow: inset 0px -1px 1px #435A6F17;
    border: 1px solid #DADADB;
}

.sideSheet .top-header, .sideSheet .bottom-footer {
    display: none;
}