.myconnect-header-section {
    margin-top: 30px;
}
.myconnect-header {
    /* padding: 9px 25px;
    display: inline-block; */
    width: 100%;
    height: 40px;
    /* */
    background-color: #0B2360 !important;
    /* position: fixed; */
    z-index: 111;
    left: 0;
}

.myconnect-header .navbar-brand {
    margin-top: 0px;
}

.myconnect-header .logo-header {
    height: 25px;
}

.myconnect-header .lt {
    margin-top: 0px;
    float: left;
}

.myconnect-header .logo img {
    width: 128px;
    max-width: 100%;
}

.l-menu-bar {
    float: left;
}
.myconnect-header .l-menu-bar .nav-bar {
    padding-left: 6px;
    color: white;
    margin-left: 20px;
}

.nav-bar {
    cursor: pointer;
}

.myconnect-header .l-menu-bar .svgimg {
    width: 18px;
    max-width: 100%;
}

.myconnect-header .l-menu-bar .m {
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 500;
}

.myconnect-header .search-bar {
    position: relative;
}

.myconnect-header .ct {
    float: left;
    width: 60%;
    margin-left: 20px;
}

.myconnect-header .search-bar img.search-icon {
    position: absolute;
    top: 10px;
    left: 14px;
    width: 15px;
}

.myconnect-header .search-bar .form-control {
    background: #0000002b !important;
    border: none;
}

.myconnect-header .search-bar .form-control {
    border-radius: 4px;
    height: 34px;
    /* margin: -6px 4px; */
    padding: 5px 0 5px 35px;
    color: white;
    font-size: 12px;
    line-height: 26px;
    width: 300px;
}

.searchclear {
    display: none;
    color: white;
    margin: -23px 0 0 294px;
    font-size: medium;
    position: absolute;
    right: 10px;
}

.myconnect-header ul {
    padding: 0;
    margin: 0;
}

.myconnect-header ul li {
    list-style: none;
    padding: 0 8px;
}

.myconnect-header .logonav li {
    float: left;
    display: block;
    margin: -3px 10px;
}

.myconnect-header .user-name {
    color: white;
}

.myconnect-header .u-pic {
    background: #0000002b !important;
    border: 1px solid #ffffff36 !important;
}

.myconnect-header .u-pic {
    width: 30px;
    display: inline-block;
    height: 30px;
    margin-bottom: 2px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    border: 1px solid #fff;
    margin-left: 8px;
    overflow: hidden;
    vertical-align: middle;
}

span.u-pic img {
    width: 100%;
    border-radius: 50%;
}

.myconnect-header .logonav li a {
    color: #fff;
    text-decoration: none;
}

.myconnect-header .logo-header {
    width: 128px;
}

.myconnect-header .logonav li a span {
    line-height: 26px;
}

.myconnect-header .logonav li a span.faq {
    border-left: 1px solid #fff;
    color: #fff;
    text-decoration: none;
    padding: 0 0 0 15px;
}

.myconnect-header .logonav li a span.help {
    color: #fff;
    text-decoration: none;
}

.sign-out .svg-icon {
    width: 14px;
    margin-left: 0px;
    padding: 4px 0;
    max-width: 100%;
}

.cmheader {
    padding: 50px 0 30px 0;
    margin: 0;
    font-size: 26px;
    display: block;
    width: 100%;
}

.myconnect-header-section {
    margin-top: 30px;
}

.myconnect-header {
    /* padding: 9px 25px;
    display: inline-block; */
    width: 100%;
    height: 40px;
    /* */
    background-color: #2141a6 !important;
    /* position: fixed; */
    left: 0;
    z-index: 9999;
}

.myconnect-header .navbar-brand {
    line-height: 1;
}

.myconnect-header .lt {
    margin-top: 0px;
    float: left;
}


/* a {
    color: #3c64c7;
    text-decoration: none;
} */

.myconnect-header .logo img {
    width: 128px;
    max-width: 100%;
}

.l-menu-bar {
    float: left;
}

.myconnect-header .l-menu-bar .nav-bar {
    padding-left: 6px;
    color: white;
    margin-left: 20px;
}

.nav-bar {
    cursor: pointer;
}

.myconnect-header .l-menu-bar .svgimg {
    width: 18px;
    max-width: 100%;
}

.myconnect-header .l-menu-bar .m {
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 500;
}

.myconnect-header .search-bar {
    position: relative;
}

.myconnect-header .ct {
    float: left;
    width: 513px;
    margin-left: 70px;
}

.myconnect-header .search-bar img.search-icon {
    position: absolute;
    top: 10px;
    left: 14px;
    width: 15px;
}

.myconnect-header .search-bar .form-control {
    background: #0000002b !important;
    border: none;
}

.myconnect-header .search-bar .form-control {
    border-radius: 2px;
    height: 32px;
    /* margin: -6px 4px; */
    padding: 5px 0 5px 35px;
    color: white;
    font-size: 12px;
    line-height: 26px;
    width: 100%;
    background-color: #3854af !important;
}

.myconnect-header .search-bar .form-control::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #fff;
}

.searchclear {
    display: none;
    color: white;
    margin: -23px 0 0 294px;
    font-size: medium;
    position: absolute;
    right: 10px;
}

.myconnect-header ul {
    padding: 0;
    margin: 0;
}

.myconnect-header ul li {
    list-style: none;
    padding: 0 8px;
}

.myconnect-header .logonav li {
    float: left;
    display: block;
    margin: -3px 10px;
}

.myconnect-header .user-name {
    color: white;
}

.myconnect-header .u-pic {
    background: #0000002b !important;
    border: 1px solid #ffffff36 !important;
}

.myconnect-header .u-pic {
    width: 30px;
    display: inline-block;
    height: 30px;
    /* margin-bottom: 2px; */
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    border: 1px solid #fff;
    /* margin-left: 8px; */
    overflow: hidden;
    vertical-align: middle;
    margin-top: 1px;
}

span.u-pic img {
    width: 100%;
    border-radius: 50%;
}

.myconnect-header .logonav li a {
    color: #fff;
    text-decoration: none;
}

.myconnect-header .logo-header {
    width: 128px;
}

.myconnect-header .logonav li a span {
    line-height: 26px;
}

.myconnect-header .logonav li a span.faq {
    border-left: 1px solid #fff;
    color: #fff;
    text-decoration: none;
    padding: 0 0 0 15px;
}

.myconnect-header .logonav li a span.help {
    color: #fff;
    text-decoration: none;
}

.sign-out .svg-icon {
    width: 14px;
    margin-left: 0px;
    padding: 4px 0;
    max-width: 100%;
}

.cmheader {
    padding: 50px 0 30px 0;
    margin: 0;
    font-size: 26px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    color: --charcoal-grey;
    text-transform: capitalize;
}

.myconnect-header .navbar-nav .dropdown-toggle:not(.custom-avatar-sm)::after {
    display: none;
}

.myconnect-header .navbar-nav .custom-avatar-sm.dropdown-toggle::after {
    color: #fff;
}

.myconnect-header .navbar-nav .custom-avatar-sm:hover {
    text-decoration: none;
    color: #fff;
}

.myconnect-header .navbar-nav .nav-item {
    cursor: pointer;
}

.myconnect-header .navbar-nav .nav-item.active {
    border-radius: 5px;
}

.myconnect-header .navbar-nav .nav-item a {
    line-height: 2;
}

.myconnect-header .navbar-nav .nav-item .custom-avatar-sm {
    line-height: 1.63 !important;
    padding: 7px 10px;
}

.myconnect-header .navbar-nav .top-arrow {
    position: absolute;
    top: -12px;
    left: auto;
    width: 0;
    right: 60px;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid white;
}

.menu-content ul {
    margin-bottom: 25px;
    margin-left: 37px;
}

.menu-content .l-menu ul {
    margin-left: 0;
    margin-bottom: 15px;
}

.myconnect-header .navbar-nav .touchstone-user-menu .top-arrow {
    top: -7px;
    right: 9px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid white;
}